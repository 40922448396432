import * as bootstrap from 'bootstrap';
const intervalTimeOut = 2000
let recipeCarousel = document.getElementById('recipeCarousel')
let mainInner = document.getElementById('main-inner')
let item = document.getElementsByClassName('carousel-item ')[0]
let nextButton = document.getElementById('next-main')
let prevButton = document.getElementById('prev-main')
let scrollPosition = 0

let numberItems = 4
setNumberItems();


addEventListener("resize", (event) => {
    setNumberItems();
});




recipeCarousel.addEventListener("mouseenter", function () {
    clearInterval(intervalNextPage);
});

// Resumes the transition when mouse leaves the slider container
recipeCarousel.addEventListener("mouseleave", () =>{changePages(numberItems)});


nextButton.addEventListener('click', () => nextPage())
prevButton.addEventListener('click', () => prevPage())

function changePages(item_per_scroll) {
    let position = scrollPosition + (item.offsetWidth * item_per_scroll)
    moveScroll(position)
    clearInterval(intervalNextPage)
    intervalNextPage = setInterval(nextPage, intervalTimeOut)
}

function nextPage(){
    if (scrollPosition < (mainInner.scrollWidth - Math.abs(item.offsetWidth * numberItems))){
        return changePages(numberItems)
    }
    moveScroll(0)

}

function prevPage(){
    if (scrollPosition > 0)
        return changePages(-numberItems)

    let lastItemPosition = mainInner.scrollWidth - item.offsetWidth
    moveScroll(lastItemPosition)
}

function moveScroll(position){
    scrollPosition = position
    mainInner.scrollTo({
        top: 0,
        left: scrollPosition,
        behavior: 'smooth'
    });
}

function setNumberItems(){
    numberItems = window.innerWidth > 767 ? 4 : 1
}

